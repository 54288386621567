import React from "react";
import { WidgetProps } from "react-jsonschema-form";
import { useQuery, Loading, Error } from "react-admin";
import {
	Select,
	MenuItem,
	Avatar
} from "@material-ui/core";

const EffectRefInput: React.FC<WidgetProps> = (props: WidgetProps) => {
	const {
		schema,
		id,
		value,
		required,
		disabled,
		readonly,
		autofocus,
		onChange,
		onBlur,
		onFocus,
		placeholder,
		formContext
	} = props;

	const { data, loading , error } = useQuery({
		type: "getList",
		resource: "visualEffects",
		payload: {
			pagination: { page: 0, perPage: -1 },
			sort: { field: "id", order: "DESC" }
		}
	});

	if (loading) return <Loading />;
	if (error) return <Error />;
	if (!data) return null;

	return (
		<>
			<Select
				style={{ marginLeft: "0.5em" }}
				id={id}
				className="form-control"
				value={typeof value === "undefined" ? "" : value}
				required={required}
				disabled={disabled || readonly}
				autoFocus={autofocus}
				onBlur={
					onBlur &&
					(event => {
						onBlur(id, (event.currentTarget as HTMLInputElement).value);
					})
				}
				onFocus={
					onFocus &&
					(event => {
						onFocus(id, (event.currentTarget as HTMLInputElement).value);
					})
				}
				onChange={event => {
					onChange((event.currentTarget as HTMLInputElement).value);
				}}
			>
				{data.map((record: any) => (
					<MenuItem value={record.key}>
						<Avatar alt="Preview" src={record.icon} />
						{record.name}
					</MenuItem>
				))}
			</Select>
		</>
	);
};

export default EffectRefInput;
