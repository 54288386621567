import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	SelectInput,
	ArrayInput,
	SimpleFormIterator,
	TextInput,
	FormDataConsumer,
	Labeled,
	NumberInput,
	addField,
	useInput,
	ReferenceArrayInput,
	SelectArrayInput,
} from "react-admin";
import {
	Card,
	Dialog,
	DialogTitle,
	Button,
	CardContent,
	Typography,
	DialogContent,
	Divider,
	Container,
	Box,
	createStyles,
	Theme,
	Fade,
	CardHeader,
	Avatar,
	IconButton,
	CardActions,
	fade,
} from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import { useForm } from "react-final-form";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import CloseIcon from "@material-ui/icons/Close";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import { useConfig } from "../hooks";
import { ModuleSources } from "../ariabo.config";
import { InputConfigLoader } from ".";
import get from "lodash/get";
var debug = require("debug")("app:components:inputdialogwrapper");

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			minWidth: 275,
		},
		cardGridContent: {
			display: "grid",
		},
		divider: {
			marginTop: "0.1rem",
			marginBottom: "0.1rem",
		},
		clearButton: {
			color: theme.palette.error.main,
		},
	})
);

const useDialogStyles = makeStyles({
	container: {
		padding: 0,
	},
	button: {
		width: "100%",
	},
});

interface InputCardBaseProps {
	title: string;
	avatarIcon?: ReactNode;
	scope?: string;
}

interface InputCardProps extends InputCardBaseProps{
	close:()=>void;
	[key: string]: any;
}

const InputCard: React.FC<InputCardProps> = (props: InputCardProps) => {
	const { avatarIcon, title, children, close, scope } = props;
	const classes = useStyles();
	const form = useForm();
	debug("InputCard props", props);
	const clear = () => {
		scope && form.change(scope, {});
	};
	return (
		<Card className={classes.root}>
			<CardHeader
				title={title}
				/*subheader={
					index !== undefined ? idToReadableRoute(source) : "Root"
				} */
				action={
					<>
						{close && (
							<IconButton onClick={() => close()}>
								<CloseIcon />
							</IconButton>
						)}
					</>
				}
				avatar={<Avatar>{avatarIcon}</Avatar>}
			/>
			<CardContent className={classes.cardGridContent}>
				{children}
			</CardContent>
			<CardActions>
				<Button color="primary" onClick={() => close()}>
					Ok
				</Button>
				{scope && (
					<Button
						startIcon={<DeleteSweepIcon />}
						classes={{ root: classes.clearButton }}
						onClick={() => clear()}
					>
						Clear
					</Button>
				)}
			</CardActions>
		</Card>
	);
};

interface InputDialogProps extends InputCardBaseProps{
	buttonLabel?:string
}

export const InputDialog: React.FC<InputDialogProps> = (props: InputDialogProps) => {
	const { buttonLabel, ...restProps } = props;
	const [open, setOpen] = React.useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	debug("InputDialog props", props);
	const classes = useDialogStyles();
	return (
		<>
			<Button
				className={classes.button}
				variant="outlined"
				color="primary"
				onClick={() => setOpen(true)}
			>
				{buttonLabel || restProps.title}
			</Button>
			<Dialog open={open} onClose={handleClose}>
				<DialogContent classes={{ root: classes.container }}>
					<InputCard {...restProps as InputDialogProps} close={handleClose} />
				</DialogContent>
			</Dialog>
		</>
	);
};


interface LabeledInputDialogProps extends InputDialogProps{
	label:string
}
const LabeledInputDialog: React.FC<LabeledInputDialogProps> = (props: LabeledInputDialogProps) => {
	const { label, ...restProps } = props;
	debug("LabeledInputDialog props", props);
	return (
		<div>
			<Labeled label={label} {...restProps}>
				{/* this guy is controlling the props of my InputDialog, careful */}
				<InputDialog {...props} />
			</Labeled>
		</div>
	);
};

export default LabeledInputDialog;
