import React from "react";
import { InputDialogWrapper, InputConfigLoader, ChipInput } from ".";
import { TextInput, Loading, SelectInput,SelectArrayInput } from "react-admin";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { useResourceSchemas } from "../hooks";
import { ModuleTypes } from "../types";
var debug = require("debug")("app:components:ddataclassificationmoduleinput");

const getCategoryChoices = (definedCategories: any) => {
	const enunvs: string[] = definedCategories["enum"];
	const enumNames: string[] = definedCategories["x-enumNames"];
	return enunvs.map((en, index) => ({
		id: en,
		name: enumNames[index],
	}));
};

const DataClassificationModuleInput: React.FC<any> = (props: any) => {
	const { source, resource } = props;
	const tagSchema = useResourceSchemas().Tags;
	const categoryChoices = React.useMemo(() => {
		if (!tagSchema) return [];
		const definedCategories = tagSchema["definitions"]["DefinedCategories"];
		return getCategoryChoices(definedCategories);
	}, [resource, tagSchema]);

	return (
		<InputDialogWrapper
			avatarIcon={<LocalOfferIcon />}
			label="Data Classification"
			title="Data Classification"
			buttonLabel="Edit"
		>
			<InputConfigLoader resource={resource}>
				{tagSchema ? (
					<>
						<SelectInput
							label="Category"
							source={`${source}.category`}
							choices={categoryChoices}
						/>
						<SelectArrayInput
							label="Classes"
							source={`${source}.classes`}
						/>
						<ChipInput
							label="Attributes"
							source={`${source}.attributes`}
							resource={resource}
						/>
						<TextInput source={`${source}.subset`} disabled label="Subset" />
					</>
				) : (
					<Loading />
				)}
			</InputConfigLoader>
		</InputDialogWrapper>
	);
};

export default DataClassificationModuleInput;
