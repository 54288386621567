import React from 'react';
import { List,
    Filter,
    TextInput,
    SelectInput,
    SearchInput
} from 'react-admin'
import ASSET_TYPES from "./asset-types";
import AssetXGrid from "./AssetXGrid";
var debug = require('debug')('app:models:assets:assetlist');

const AssetFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="name" alwaysOn />
        <SelectInput source="assetType" choices={Object.keys(ASSET_TYPES).filter(i=>typeof ASSET_TYPES[i] === 'string').map(v=>(
                {id:Object.keys(ASSET_TYPES).indexOf(v)-1, name:v}
            ))}/>
        <TextInput label="Attribute" source="attributes" />

        {/* <ReferenceInput resource={"assets/usedBy"} reference="tags" source="usedBy" label="Used by tag" perPage={0} >
            <SelectInput optionText="name" />
        </ReferenceInput> */}
    </Filter>
);

export default class extends React.Component {
    render() {
        const {
            props,
        } = this;

        return (
            <List title={"root.resources.assets"} perPage={25} {...props} filters={<AssetFilter />} sort={{field:"id", order:"DESC"}}>
                <AssetXGrid />
            </List>
        );
    }
}
