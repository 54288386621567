import React from 'react';
import InputRange from 'react-input-range';
import { addField, Labeled } from 'react-admin';
import "./RangeInput.css";
var debug = require('debug')('app:components:rangeinput')


class RangeInput extends React.Component {
    constructor(props) {
        super(props);
     
        this.state = {
          value: {min:props.min, max: props.max},
        };
      }
     
      render() {
        const {max, min, formatLabel, step, label, input, ...restProps} = this.props;
        debug("my props", this.props)
        return (
            <Labeled classname={this.props.classes.root} label={this.props.label}>
                <InputRange
                    maxValue={max}
                    minValue={min}
                    formatLabel={formatLabel || (value => `${Number(value).toFixed(2)}`)}
                    step={step || 1}
                    label={label}
                    {...restProps}
                    {...input}
                />
            </Labeled>

        );
      }
    
}
export default addField(RangeInput); // decorate with redux-form's <Field>