import { stringify } from "query-string";
import { fetchUtils, DataProvider } from "ra-core";
import { GetConfig } from "../config";
import { postProcess, preProcess, preProcessMany } from "./sanitas";
import { dataOpAcess, getDataRoutes } from "../ariabo.config";
import {
	GET_LIST,
	GET_ONE,
	GET_MANY,
	GET_MANY_REFERENCE,
	CREATE,
	UPDATE,
	UPDATE_MANY,
	DELETE,
	DELETE_MANY
} from "react-admin";
var debug = require("debug")("app:dataprovider");

const authenticatedClient = (url: string, options: any = {}) => {
	debug("authenticatedClient options>", options);
	if (!options.headers) {
		options.headers = new Headers({ Accept: "application/json" });
	}
	const token = localStorage.getItem("token");
	debug("authenticatedClient options>", options);

	debug("authenticatedClient options is something", token, options);
	options.headers.set("Authorization", `Bearer ${token}`); //cant find another way.........
	return fetchUtils.fetchJson(url, options);
};

const resolveAcess = (type: string, resource: string, params: any) => {
	const dataOpAcessThatIsNotRetarded = dataOpAcess as any;
	if (
		dataOpAcessThatIsNotRetarded &&
		dataOpAcessThatIsNotRetarded[resource] &&
		!dataOpAcessThatIsNotRetarded[resource](type, resource, params)
	) {
		return Promise.reject({ error: new Error("Unauthorized") });
	} else return Promise.resolve({ error: undefined });
};

const resolveRouteAndPostSanitize = async (
	type: string,
	resource: string,
	params: any
) => {
	if (params && params.data)
		params.data = await postProcess(resource, params.data);
	const customroute = getDataRoutes().find(i => i.from === resource);
	if (customroute) {
		if (
			customroute.withFilter &&
			(type === GET_LIST || type === GET_ONE || type === GET_MANY)
		)
			params.filter = {
				...params.filter,
				[customroute.withFilter.field]: customroute.withFilter.value
			};
		resource = customroute.to;
		if (params && params.data)
			params.data = await postProcess(resource, params.data);
	}
	return {
		resource,
		params
	};
};

const preSanitizeData = async (resource: string, record: any) => {
	return await preProcess(resource, record);
}

const preSanitizeMany = async (resource: string, records: any[]) => {
	return await preProcessMany(resource, records);
}

const REST_API_URL = GetConfig()["apiUrl"]

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

function flattenObj(obj: any, parent?: any, res: any = {}) {
	for (let key in obj) {
		let propName = parent ? parent + '.' + key : key;
		if (typeof obj[key] == 'object') {
			flattenObj(obj[key], propName, res);
		} else {
			res[propName] = obj[key];
		}
	}
	return res;
}

export default (
	apiUrl: string = GetConfig()["apiUrl"],
	httpClient = authenticatedClient
): DataProvider => ({
	getList: async (resource, params) => {
		const acess = await resolveAcess(GET_LIST, resource, params);
		if (acess.error) return Promise.reject(acess.error);
		const resolved = await resolveRouteAndPostSanitize(
			GET_LIST,
			resource,
			params
		);
		resource = resolved.resource;
		params = resolved.params;
		if (params["filter"] && params["filter"]["name"]) { //remember to later use Text Index
			const name = { $regex: `${params["filter"]["name"]}`, "$options": "i" };
			delete params["filter"]["name"];
			params["filter"]["name"] = name;
		}
		if ("modules" in params["filter"]) {
			const moduleFilters = params["filter"]["modules"];
			delete params["filter"]["modules"];
			const flattened = flattenObj(moduleFilters, "modules");
			params["filter"] = {
				...params["filter"],
				...flattened
			}
		}
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			sort: JSON.stringify([field, order]),
			range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			filter: JSON.stringify(params.filter)
		};

		const url = `${apiUrl}/${resource}?${stringify(query)}`;
		const requestResult = await httpClient(url);
		if (requestResult.headers === null || requestResult.headers === undefined) {
			throw new Error(
				"Headers cannot be null! Make sure the server is returning headers?"
			);
		}
		if (!requestResult.headers.has("content-range")) {
			throw new Error(
				"The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?"
			);
		}
		const processedData = await preSanitizeMany(resource, requestResult.json) as any;
		debug("GET LIST PROCESSED DATA", processedData);
		return {
			data: processedData,
			total: parseInt(
				(requestResult.headers.get("content-range") as any) //if its undefined it'll be thrown above ^
					.split("/")
					.pop()
			)
		};

	},

	getOne: async (resource, params) => {
		const acess = await resolveAcess(GET_ONE, resource, params);
		if (acess.error) return Promise.reject(acess.error);
		const resolved = await resolveRouteAndPostSanitize(
			GET_MANY,
			resource,
			params
		);
		resource = resolved.resource;
		params = resolved.params;
		const requestResult = await httpClient(`${apiUrl}/${resource}/${params.id}`);
		const processedData = await preSanitizeData(resource, requestResult.json) as any;
		return {
			data: processedData
		};
	},

	getMany: async (resource, params) => {
		const acess = await resolveAcess(GET_MANY, resource, params);
		if (acess.error) return Promise.reject(acess.error);
		const resolved = await resolveRouteAndPostSanitize(
			GET_MANY,
			resource,
			params
		);
		resource = resolved.resource;
		params = resolved.params;
		const query = {
			filter: JSON.stringify({ id: params.ids })
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;
		const requestResult = await httpClient(url);
		const processedData = await preSanitizeMany(resource, requestResult.json) as any;
		return {
			data: processedData
		}
	},

	getManyReference: async (resource, params) => {
		const acess = await resolveAcess(GET_MANY_REFERENCE, resource, params);
		if (acess.error) return Promise.reject(acess.error);
		const resolved = await resolveRouteAndPostSanitize(
			GET_MANY_REFERENCE,
			resource,
			params
		);
		resource = resolved.resource;
		params = resolved.params;
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			sort: JSON.stringify([field, order]),
			range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			filter: JSON.stringify({
				...params.filter,
				[params.target]: params.id
			})
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		const requestResult = await httpClient(url);
		if (requestResult.headers === null || requestResult.headers === undefined) {
			throw new Error(
				"Headers cannot be null! Make sure the server is returning headers?"
			);
		}
		if (!requestResult.headers.has("content-range")) {
			throw new Error(
				"The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?"
			);
		}
		const processedData = await preSanitizeMany(resource, requestResult.json) as any;
		return {
			data: processedData,
			total: parseInt(
				(requestResult.headers.get("content-range") as any).split("/").pop()
			)
		}
	},

	update: async (resource, params) => {
		const acess = await resolveAcess(UPDATE, resource, params);
		if (acess.error) return Promise.reject(acess.error);
		const resolved = await resolveRouteAndPostSanitize(
			UPDATE,
			resource,
			params
		);
		resource = resolved.resource;
		params = resolved.params;
		const requestResult = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: "PUT",
			body: JSON.stringify(params.data)
		});
		return {
			data: { ...params.data }
		}
	},

	// simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
	///added by helio, we supposedly don't use this
	updateMany: async (resource, params) => {
		return Promise.reject("UpdateMany not supported")
		return Promise.all(params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
			method: "PUT",
			body: JSON.stringify(params.data)
		}))).then(responses => ({ data: responses.map(({ json }) => json.id) }));
	},

	create: async (resource, params) => {
		const acess = await resolveAcess(CREATE, resource, params);
		if (acess.error) return Promise.reject(acess.error);
		const resolved = await resolveRouteAndPostSanitize(
			CREATE,
			resource,
			params
		);
		resource = resolved.resource;
		params = resolved.params;
		return httpClient(`${apiUrl}/${resource}`, {
			method: "POST",
			body: JSON.stringify(params.data)
		}).then(({ json }) => ({
			data: { ...json }
		}));
	},

	delete: async (resource, params) => {
		const debug = require("debug")("app:dataprovider:delete");
		debug("0", resource, params)
		const acess = await resolveAcess(DELETE, resource, params);
		debug("1", acess)
		if (acess.error) return Promise.reject(acess.error);
		debug("2", resource, params)
		const resolved = await resolveRouteAndPostSanitize(
			DELETE,
			resource,
			params
		);
		debug("3", resolved)
		resource = resolved.resource;
		params = resolved.params;
		debug("4", apiUrl, resource, params.id)
		const requestResult = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: "DELETE"
		});
		return ({
			data: requestResult.json
		})
	},

	// simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
	deleteMany: async (resource, params) => {
		const acess = await resolveAcess(DELETE_MANY, resource, params);
		if (acess.error) return Promise.reject(acess.error);
		const resolved = await resolveRouteAndPostSanitize(
			DELETE_MANY,
			resource,
			params
		);
		resource = resolved.resource;
		params = resolved.params;
		return Promise.all(
			params.ids.map(id =>
				httpClient(`${apiUrl}/${resource}/${id}`, {
					method: "DELETE"
				})
			)
		).then(responses => ({ data: responses.map(({ json }) => json.id) }));
	}
});

export { default as fileServer } from "./file";
export { default as restServer } from "./rest";