// in PostReferenceInput.js
import React, { Fragment } from 'react';
import { ReferenceInput, SelectInput, required, regex } from 'react-admin';
import { Field } from "react-final-form";
import { AssetQuickPreviewButton, ASSET_TYPES } from '../asset';
var debug = require('debug')('app:models:exp:assetreferenceinput');

class CustomSelectInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            choices: undefined
        }
        this.transformChoices();
    }

    transformChoices = () => {
        let newChoices = [];
        for (let choice of this.props.choices) {
            newChoices.push({
                ...choice,
                name_icon: choice.name + "_" + choice.assetType
            })
        }
        this.setState({ choices: newChoices })
    }
    renderValue = (record) => (
        <span>
            {ASSET_TYPES.icons[record.assetType]} {record.name}
        </span>
    )
    render() {
        const {
            props,
        } = this;
        return <SelectInput {...props} optionText={this.renderValue} />;
    }
}

const validadeReference = [required(), regex(/^[a-f\d]{24}$/i, "yes")]
class AssetReferenceInput extends React.Component {
    render() {
        const {
            props,
        } = this;
        let {resourceCollectorOptions} = props;
        if(!resourceCollectorOptions)resourceCollectorOptions={};
        debug("props id and source", props.id, props.source)
        debug("rest props", props);
        return (
            <Fragment>
                <ReferenceInput label="Asset" validate={validadeReference} allowEmpty={false}  {...props} {...props.options} >
                    <CustomSelectInput allowEmpty={false} />
                </ReferenceInput>
                <div style={{ display: 'inline-flex' }}>
                    {/* <AssetQuickCreateButton {...props} resource="assets" /> */}
                    {/* <span style={{ width: '10px' }}></span> */}
                    <Field
                        name={props.source}
                        component={({ input }) => {
                            debug("new input", props.source, input);
                            return input.value && typeof input.value === "string" && <AssetQuickPreviewButton id={input.value} />
                        }
                        }
                    />
                </div>
            </Fragment>
        );
    }
}

export default AssetReferenceInput;