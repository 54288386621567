import React, { Component } from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    required,
} from 'react-admin';
import { ChipInput } from '../../components';


class UserEdit extends Component {
    componentDidMount() {
        // document.title = Config.app.name + ' - Edit User';
    }

    render() {
        return (
            <Edit title="Edit" {...this.props}>
                <TabbedForm defaultValue={{ role: 'user' }} redirect="list">
                    <FormTab label="User Info" path="">
                        <TextInput source="id" disabled />
                        <TextInput source="userName" validate={[required()]} />
                        <TextInput type="email" source="email" validate={[required()]} />
                        <TextInput source="password" />
                    </FormTab>

                    <FormTab label="Role" path="security">
                        <ChipInput label="Roles" source="roles" validate={[required()]} />
                    </FormTab>
                </TabbedForm>
            </Edit>
        );
    }
}

export default UserEdit;