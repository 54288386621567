import React from "react";
import { Card, Typography } from "@material-ui/core";
import { useAuthorizedDataFetch } from "../hooks";
import QueryString from "query-string";
import getDataRouteProperties from "../utils/getDataRouteProperties";
import { Loading } from "react-admin";
import Moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

let allViews = Object.keys(Views).map((k) => Views[k]);

const DATE_FORMAT = "YYYY-MM-DD";

const RecordsSchedule: React.FC<any> = (props: any) => {
	const { onChange, date } = props;

	return <div></div>;
};

const ResourceSchedule: React.FC<any> = (props: any) => {
	return <RecordsSchedule {...props} />;
};

const localizer = momentLocalizer(Moment);

interface ResourceSchedule {
	resources: Array<{ id: string | number; title: string }>;
	events: Array<{
		start_time: Moment.Moment;
		end_time: Moment.Moment;
		group: string | number;
		title: string;
	}>;
}

const ColoredDateCellWrapper = (props: any) => {
	const { children } = props;
	return React.cloneElement(React.Children.only(children), {
		style: {
			backgroundColor: "lightblue",
		},
	});
};

const ResourceScheduleController: React.FC<any> = (props: any) => {
	const { resource } = props;
	const [date, setDate] = React.useState(new Date());
	const aditionalResourceFilters = React.useMemo(
		() => getDataRouteProperties(resource),
		[resource]
	);
	const { data, loading, error } = useAuthorizedDataFetch(
		`tags/getSchedule?` +
			QueryString.stringify({
				filter: JSON.stringify({
					"modules.triggers": { $ne: null },
					...aditionalResourceFilters,
				}),
			}),
		[resource]
	);
	console.log("data", data);
	const events = React.useMemo(() => {
		const evs: Array<any> = [];
		if (data && data.length > 0) {
			(data as Array<any>).reduce((evArr, current, index) => {
				evArr.push(
					...current["events"].map(
						(ev: any, event_index: number) => ({
							id: `${index}.${event_index}`,
							title: current["name"],
							resource: current["id"],
							start: new Date(Date.parse(ev.start)),
							end: new Date(Date.parse(ev.end)),
							allDay:
								Date.parse(ev.end) - Date.parse(ev.start) === 0,
						})
					)
				);
				return evArr;
			}, evs);
		}
		return evs;
	}, [resource, data]);
	console.log("data", events);
	if (loading) return <Loading />;
	if (!events || events.length===0)
		return (
			<Card style={{ padding: "1em" }}>
				<Typography variant="subtitle1">
					No calendar data found
				</Typography>
			</Card>
		);

	return (
		<div style={{ height: "90vh" }}>
			<Calendar
				localizer={localizer}
				events={events}
				startAccessor="start"
				endAccessor="end"
				step={60}
				showMultiDayTimes
				components={{
					timeSlotWrapper: ColoredDateCellWrapper,
				}}
			/>
		</div>
	);
};

export default ResourceScheduleController;