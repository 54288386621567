import React, { ReactNode } from "react";
import {
	List,
	Datagrid,
	DeleteButton,
	EditButton,
	CreateButton,
	ExportButton,
	CardActions,
	Responsive,
	UPDATE,
	DELETE,
	useListContext,
	TextField,
} from "react-admin";
import TagFilter from "../TagFilter";
import {
	ButtonToRecordsMapViewer,
	PublishButton,
	AccessControlledFeature,
	ButtonToResourceCalendar,
} from "../../../components";
import { ExpField } from "../../../types";
import { Fields } from "./Fields";
import MobileGrid from "./MobileGrid";
import BulkActs from "../TagBulkActionButtons";


import { Button } from '@material-ui/core';

const debug = require("debug")("app:models:customExp:list");

///cleanup later
const HeaderActions: (...args: any[]) => any = ({
	bulkActions,
	basePath,
	currentSort,
	displayedFilters,
	exporter,
	filters,
	filterValues,
	onUnselectItems,
	resource,
	selectedIds,
	showFilter,
	hasCreate,
}) => (
		<CardActions>
			{bulkActions &&
				React.cloneElement(bulkActions, {
					basePath,
					filterValues,
					resource,
					selectedIds,
					onUnselectItems,
				})}
			{filters &&
				React.cloneElement(filters, {
					resource,
					showFilter,
					displayedFilters,
					filterValues,
					context: "button",
				})}
			<ButtonToRecordsMapViewer resource={resource} basePath={basePath} />
			<ButtonToResourceCalendar resource={resource} basePath={basePath} />
			{hasCreate && <CreateButton basePath={basePath} />}
			<Responsive
				small={<div></div>}
				medium={
					<ExportButton
						resource={resource}
						sort={currentSort}
						filter={filterValues}
						exporter={exporter}
					/>
				}
			/>
		</CardActions>
	);



const ExpList: React.FC<any> = (props: any) => {
	const { options, permissions } = props;
	const fields: ExpField[] = options && options["fields"];
	const children = fields.filter((f) => Fields[f]).map((f) => Fields[f]);

	debug("list props", props);
	return (
		<List
			sort={{ field: "id", order: "DESC" }}
			actions={<HeaderActions />}
			bulkActionButtons={<BulkActs permissions={permissions} />}
			title={options && (options.title || options.label)}
			filters={<TagFilter sources={["name","classes", "isPublished", "publicState", "attributes"]} />}
			{...props}
		>
			<Responsive
				small={<MobileGrid permissions={permissions} history={props.history} />}
				medium={
					<Datagrid rowClick="edit">
						{children}
					
						<AccessControlledFeature feature="PUBLISH">
							<PublishButton />
						</AccessControlledFeature>
						{/* <StateButton permissions={permissions} /> */}
						<AccessControlledFeature feature={UPDATE}>
							<EditButton />
						</AccessControlledFeature>
						<AccessControlledFeature feature={DELETE}>
							<DeleteButton />
							{/* <DeleteDialog
								{...props}
								dialogConditional={(record: any) => {
									return record.stateInfo.publishedId;
								}}
								dialogTitle="Delete"
								dialogText="Are you sure you want to delete the selected tag(s)? Selection contains published experience(s)."
							/> */}
						</AccessControlledFeature>
						
					</Datagrid>
				}
			/>
		
		</List>
	);
};

// const ExpList = debounceRender(ExpList_MOD, 10, { leading: false });
export default ExpList;
