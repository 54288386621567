// in LatLongInput.js
import { Field } from 'redux-form';
import { Labeled, addField } from 'react-admin';
import { VectorInputProps } from './types'
import React from 'react';
import { withStyles, createStyles } from '@material-ui/core';

const styles =createStyles({
    div:{
        display:'inline-grid',
        gap:'5px',
        gridTemplateAreas:"'a a a'"
    },
    field:{
        width:'50px'
    }
})

const inpt = (props: VectorInputProps) => {
    const {name, source, xProps, yProps, classes, label, ...restProps} = props;
    return (<Labeled label={label}>
        <div className={classes.div}>
            <Field className={classes.field} name={source+'.x'} component="input" type="number" placeholder="X" {...restProps}/>
            <Field className={classes.field} name={source+'.y'} component="input" type="number" placeholder="Y" {...restProps}/>
            {restProps.threeD&&
                <>
                    <Field className={classes.field} name={source+'.z'} component="input" type="number" placeholder="Z" {...restProps}/>
                </>
            }
        </div>
    </Labeled>
    )};

export default withStyles(styles)(addField(inpt));