import React from 'react'
import {
    Filter,
    TextInput,
    ReferenceInput,
    SelectInput,
    NullableBooleanInput,
    SearchInput
} from 'react-admin';
import { TagTypes } from './utils';
import PropTypes from 'prop-types';
import { ChipInput, InputConfigLoader } from '../../components';
import { useConfig } from '../../hooks';

const filterDict = {
    name: <SearchInput
        key='t_filter_0'
        source="name"
        alwaysOn />,
    //attributes: <ChipInput key='t_filter_5' label="Attributes" source="modules.classification.attributes" />,
    attributes: <TextInput key='t_filter_5' label="Attribute" source="modules.classification.attributes" />,
    group:
        <ReferenceInput key='t_filter_1' label="Group" source="group.expGroups_id" reference="expGroups" allowEmpty={true} >
            <SelectInput optionText="name" />
        </ReferenceInput>,
    brand:
        <ReferenceInput key='t_filter_2' label="Brand" source="group.expGroups_id" reference="expGroups" allowEmpty={true} >
            <SelectInput optionText="name" />
        </ReferenceInput>,
    type:
        <SelectInput key='t_filter_3' source="type" choices={Object.keys(TagTypes).map(v => (
            { id: TagTypes[v], name: v }
        ))} />,
    isPublished:
        <NullableBooleanInput key="t_filter_6" label="Is Published" source="#isPublished" />,
    publicState:
        <SelectInput key='t_filter_7' label="Public State" source="publicState" choices={[{ name: "Updated", id: 2 }, { name: "Outdated", id: 1 }]} />,
    createdBy:
        <ReferenceInput key='t_filter_4' label="Author" source="createdBy" reference="users" allowEmpty={true} >
            <SelectInput optionText="userName" />
        </ReferenceInput>,
    classes: <InputConfigLoader key='t_filter_9' label="Classes" source="modules.classification.classes">
        <SelectInput key='t_filter_8' label="Classes" source="modules.classification.classes" />
    </InputConfigLoader>
    // <SelectInput key='t_filter_4' source="stateInfo.createdBy" choices={Object.keys(TagTypes).map(v=>(
    //     {id:TagTypes[v], name:v}
    // ))}/>
}
const TagFilter = ({ searchboxonly = false, setCustomFilter = undefined, sources, ...props }) => (
    <Filter {...props}>
        {searchboxonly ? (<TextInput label="Search" key="1c" source="name" alwaysOn />) : sources.map(i => filterDict[i])}
    </Filter>
);

TagFilter.propTypes = {
    sources: PropTypes.array,
    searchboxonly: PropTypes.bool
}

export default TagFilter;